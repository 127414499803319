import { FC, useEffect, useRef, useState } from 'react'
import { Network } from '@capacitor/network'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { cloudDownloadOutline, alert } from 'ionicons/icons'
//
import JSZip, { JSZipObject } from 'jszip'
import { Filesystem, Encoding, Directory } from '@capacitor/filesystem'
// hooks
import { useNetwork } from '../../contexts/NetworkContext'
import { useDownload } from '../../contexts/DownloadContext'
import { useUserEditBookStats } from '../../hooks/useUserQueries'
import { twMerge } from 'tailwind-merge'

interface DownloadButtonProps {
  uid: string
  bid: string
  downloadUrl: string
  slot?: string
  classname?: string
}

const DownloadButton: FC<DownloadButtonProps> = ({ bid, uid, downloadUrl, slot, classname }) => {
  const { mutate, isError, isLoading: isEditing } = useUserEditBookStats(uid, bid)
  const urlRef = useRef<string>('') // Ref to store the current URL
  // const [url, loading, error] = useDownloadURL(storageRef(storage, downloadUrl))
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  // const [connected, setConnected] = useState<boolean>(true)

  const { connected } = useNetwork()
  const { progress, inProgress, setProgress, setInProgress, unzipping, setUnzipping } = useDownload()

  useEffect(() => {
    // listen for network status change connected
    if (!connected) {
      setInProgress(bid, false)
      setUnzipping(bid, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, setUnzipping, setInProgress, bid])

  if (isError) console.log('Error in Download Book', isError)

  useEffect(() => {
    setProgress(bid, 0)
    Network.getStatus().then((status) => {
      if (status.connected) {
        setLoading(true)
        console.log('Downloading:0', downloadUrl)
        setLoading(false)
        setUrl(downloadUrl)
        urlRef.current = downloadUrl // Set the ref to the current URL
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid, downloadUrl])

  const handleDownload = async () => {
    try {
      // if (!url) {
      if (!urlRef.current) {
        throw new Error('No URL provided')
      }
      setUnzipping(bid, true)

      setInProgress(bid, true)

      const startTimestamp = Date.now()
      const response = await fetch(url as string)
      const totalSize = response.headers.get('content-length')
      if (!totalSize) {
        console.log('Downloading:4 Content length not present in headers.', response.headers)
        throw new Error('Content length not present in headers.')
      }

      // updateProgress(dataArrayBuffer.byteLength, parseInt(totalSize))
      if (response.body) {
        const readableStream = response.body.getReader()
        let receivedSize = 0

        const chunks: Uint8Array[] = []

        const condition = true
        while (condition) {
          const { done, value } = await readableStream.read()

          if (done) {
            break
          }

          if (!value) {
            throw new Error('No value received from stream')
          } else {
            chunks.push(value)
            receivedSize += value.length
          }

          if (totalSize) {
            const newProgress = Math.round((receivedSize / parseInt(totalSize)) * 100)
            if (newProgress !== progress[bid]) {
              setProgress(bid, newProgress)
            }
          }
        }

        const data = new Blob(chunks)

        const reader = new FileReader()
        reader.onloadend = () => {
          console.log('Download complete X')
          const endTimestamp = Date.now()
          console.log('Download complete time X:', endTimestamp - startTimestamp)
          setInProgress(bid, false)
        }

        reader.onerror = (error) => {
          console.log('Download error:', error)
          setInProgress(bid, false)
        }

        reader.readAsArrayBuffer(data)

        if (!inProgress[bid]) {
          if (unzipping[bid]) {
            console.log('Another download is already in progress. Skipping unzipping.')
          } else {
            // setEditing(true)

            const zip = new JSZip()
            const zipData = await zip.loadAsync(data)

            await createParentDirectories(Object.values(zipData.files)) // Create parent directories before extracting files

            await handleFiles(Object.values(zipData.files))
            console.log('unzipping done')
          }

          console.log('done')
          await Promise.all([mutate({ downloaded: true })])
        }
      }
    } catch (error) {
      console.log('DOWNLOAD ERROR', error)
    } finally {
      // setTimeout(() => {
      setInProgress(bid, false)
      // }, 3000)
    }
  }

  const createParentDirectories = async (files: JSZipObject[]) => {
    const parentDirectories = new Set<string>()

    files.forEach((file) => {
      if (file.dir) {
        const parentDir = file.name.substring(0, file.name.lastIndexOf('/'))
        if (parentDir) {
          parentDirectories.add(parentDir)
        }
      }
    })

    for (const dir of parentDirectories) {
      try {
        await Filesystem.mkdir({
          path: dir,
          directory: Directory.Data,
          recursive: true
        })
        console.log('Created parent directory:', dir)
      } catch (error) {
        console.log('Error creating parent directory:', error)
      }
    }
  }

  const handleFiles = async (files: JSZipObject[]) => {
    await Promise.all(
      files.map(async (file) => {
        if (!file.dir) {
          try {
            let fileData
            if (file.name.endsWith('.json')) {
              fileData = await file.async('text')
            } else {
              fileData = await file.async('base64')
            }
            await Filesystem.writeFile({
              path: file.name,
              data: fileData,
              directory: Directory.Data,
              encoding: file.name.endsWith('.json') ? Encoding.UTF8 : undefined
            })
            // console.log('Saved file:', savedFile)
          } catch (error) {
            console.log('Error saving file:', error)
          }
        }
        // console.log('File:', file)
      })
    ).finally(() => {
      setUnzipping(bid, false)
    })
  }

  const renderButtonValue = () => {
    /**
     * 1. If download is in progress, show progress
     * 2. If unzipping is in progress, show spinner
     * 3. If download is not in progress, show download button
     * 4. If Edit is in progress, show spinner
     */
    if (inProgress[bid]) {
      return <span className="h-full flex justify-center items-center">{`${progress[bid]}%`}</span>
    } else if ((unzipping[bid] || isEditing) && !inProgress[bid] && connected) {
      return <IonSpinner name="dots" color="light" />
    } else if (!unzipping[bid] && !inProgress[bid] && !isEditing && connected) {
      return (
        <>
          تحميل
          <IonIcon slot="end" icon={cloudDownloadOutline} className="w-5 h-5 pr-1" />
        </>
      )
    }
    if (!connected)
      return (
        <>
          تحميل
          <IonIcon slot="end" icon={alert} className="w-5 h-5 pr-1" />
        </>
      )
    return <IonSpinner name="dots" color="light" />
  }

  return (
    <IonButton
      slot={slot}
      // size="small"
      // className="text-sm sm:text-lg w-[80px] sm:w-[110px] h-[35px] text-center"
      // expand="block"
      // className="text-xl"
      className={twMerge(' text-sm md:text-xl w-[100px] lg:w-[140px]', classname)}
      color="quaternary"
      // shape="round"
      fill="solid"
      disabled={!connected || loading || inProgress[bid] || unzipping[bid] || isEditing}
      onClick={(e) => {
        e.stopPropagation()
        handleDownload()
      }}
    >
      {renderButtonValue()}
    </IonButton>
  )
}

export default DownloadButton
