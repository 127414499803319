import { useEffect } from 'react'
import { App as CapApp } from '@capacitor/app'
import { Preferences } from '@capacitor/preferences'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, setupIonicReact, isPlatform, useIonRouter } from '@ionic/react'
// components
import { Loader } from './components'
// hooks
import { useBooks } from './hooks/useLibrary'
import { useAuth } from './contexts/AuthContext'
import { useFirstTime, useLoggedIn, useSetFirstTime } from './hooks/useIntro'
// routes
import Routes from './Routes'
// Intro Page
import Introduction from './pages/Introduction'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
/* Theme variables */
import './theme/variables.css'
import './theme/globals.css'
import './theme/fonts.css'

setupIonicReact({
  platform: {
    /** The default `desktop` function returns false for devices with a touchscreen.
     * This is not always wanted, so this function tests the User Agent instead.
     **/
    desktop: (win) => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent)
      return !isMobile
    }
  }
})

const App: React.FC = () => {
  const router = useIonRouter()
  const { data: loggedIn } = useLoggedIn()
  const { isInitialized } = useAuth()
  const { data: firstTime } = useFirstTime()
  const { mutate: setFirstTime } = useSetFirstTime()

  const LOCATION = window.location.pathname
  const isSupport = LOCATION.includes('support')

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(-1, () => {
      console.log('Back button clicked', router.routeInfo)
      if (router.canGoBack()) {
        router.goBack()
      } else {
        if (isPlatform('hybrid')) {
          CapApp.exitApp()
        } else {
          router.push('/')
        }
      }
    })
  })

  const getPreferences = async () => {
    const isFirstTime = await Preferences.get({ key: 'firstTime' })
    const isLogged = await Preferences.get({ key: 'loggedIn' })
    return { isFirstTime, isLogged }
  }

  useEffect(() => {
    if (isPlatform('hybrid'))
      getPreferences().then((res) => {
        !res['isLogged'].value ? setFirstTime(true) : setFirstTime(false)
      })
    else {
      !loggedIn && setFirstTime(true)
    }
  }, [loggedIn, setFirstTime])

  const { isLoading } = useBooks()

  if (!isInitialized || isLoading) {
    return <Loader />
  }

  return (
    <IonApp>
      <IonReactRouter>{firstTime && !isSupport ? <Introduction /> : <Routes />}</IonReactRouter>
    </IonApp>
  )
}

export default App
