// import { filter } from 'lodash'
import { FC, useState } from 'react'
import { sha256 } from 'hash-wasm'
import { twMerge } from 'tailwind-merge'
import {
  isPlatform,
  useIonRouter,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
  useIonToast,
  useIonAlert,
  IonImg
} from '@ionic/react'
// firebase hooks
import { Auth, signInWithCredential_ } from '../../firebase/config'
import { Errors } from '../../firebase/error-codes'
import { useSignInWithGoogle, useSignInWithApple } from 'react-firebase-hooks/auth'
// native google and apple sign in
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
// sign-in with Apple
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import {
  OAuthProvider,
  signInWithCredential,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithCredential
} from 'firebase/auth'
//
import { navigateToSubscribe } from '../../utils/subscriptionPath'
//
import { useAuth } from '../../contexts/AuthContext'
import { useSetLoggedIn } from '../../hooks/useIntro'
import { useEditUserData } from '../../hooks/useUser'
import { useNetwork } from '../../contexts/NetworkContext'

// const options: SignInWithAppleOptions = {
//   clientId: 'ca.digitalfuture.mystories',
//   redirectURI: 'https://stories-app-qissasi.firebaseapp.com/__/auth/handler',
//   scopes: 'email, name',
//   state: '12345',
//   nonce: ''
// }

interface SocialAuthProps {
  AnonymousSignIn?: boolean
}

const SocialAuth: FC<SocialAuthProps> = ({ AnonymousSignIn }) => {
  const [appleLinking, setAppleLinking] = useState<boolean>(false)
  const [googleLinking, setGoogleLinking] = useState<boolean>(false)
  const [errorAppleMsg, setErrorAppleMsg] = useState<string>('')
  const [errorGoogleMsg, setErrorGoogleMsg] = useState<string>('')

  // const [connected, setConnected] = useState<boolean>(true)
  // Network.addListener('networkStatusChange', (status) => {
  //   setConnected(status.connected)
  // })

  const { user } = useAuth()
  const { connected } = useNetwork()
  const { mutate: setLoggedIn } = useSetLoggedIn()
  const { mutate: editUserData } = useEditUserData(user?.uid)
  const [signInWithApple, , appleLoading, appleError] = useSignInWithApple(Auth)
  const [signInWithGoogle, , googleLoading, googleError] = useSignInWithGoogle(Auth)

  const router = useIonRouter()
  const [presentToast] = useIonToast()
  const [presentAlert] = useIonAlert()

  const IS_ANDROID = isPlatform('android')

  const handleGoogleSignIn = async () => {
    // detect if user using web or native
    if (isPlatform('hybrid')) {
      console.log('hybrid')
      // signInWithGoogle()
      setGoogleLinking(true)
      await GoogleAuth.signOut()
      GoogleAuth.signIn()
        .then((res: any) => {
          // console.log('Google Auth Response', JSON.stringify(res))
          const { idToken, accessToken } = res.authentication ? res.authentication : res
          // console.log('idToken', idToken)
          // console.log('accessToken', accessToken)
          const credential = GoogleAuthProvider.credential(idToken, accessToken)
          // sign in with credential
          if (AnonymousSignIn) {
            if (!Auth.currentUser) return
            linkWithCredential(Auth.currentUser, credential)
              .then((res: any) => {
                // console.log('linkWithCredential', res)
                editUserData({ isAnonymous: false, email: res.user.email })
                setGoogleLinking(false)
                router.push(navigateToSubscribe())
              })
              .catch((error: any) => {
                console.error('linkWithCredential', error)
                if (error.code === 'auth/credential-already-in-use') {
                  openGoogleAlert(idToken)
                }
                setGoogleLinking(false)
              })
          } else {
            signInWithCredential_({ idToken, accessToken }).then((res) => {
              console.log('Successfully signed in with Google 1!')
              setLoggedIn(true)
            })
          }
          setGoogleLinking(false)
        })
        .catch((err) => {
          console.error('Google Auth Error', err)
          setGoogleLinking(false)
          presentToast({
            message: Errors[err.message] || err.message,
            duration: 3000,
            position: 'top',
            color: 'danger',
            cssClass: 'text-center'
          })
        })
    } else {
      console.log('web')
      if (AnonymousSignIn) {
        setGoogleLinking(true)
        const provider = new GoogleAuthProvider()
        if (!Auth.currentUser) return
        linkWithPopup(Auth.currentUser, provider)
          .then((result) => {
            // Accounts successfully linked.
            GoogleAuthProvider.credentialFromResult(result)
            const resUser = result.user
            // console.log('credential', credential)
            // console.log('resUser', resUser)
            editUserData({ isAnonymous: false, email: resUser?.email })
            setGoogleLinking(false)
            router.push(navigateToSubscribe())
          })
          .catch((error) => {
            console.error('Link popup error: ', error)
            // handle if account already exists
            if (error.code === 'auth/credential-already-in-use') {
              openGoogleAlert('')
            }
            setGoogleLinking(false)
          })
      } else {
        signInWithGoogle().then(() => {
          console.log('Successfully signed in with Google 2!')
          setLoggedIn(true)
        })
      }
    }
  }

  const handleAppleSignIn = async () => {
    const hashedNonce = await sha256('nonce')
    const options: SignInWithAppleOptions = {
      clientId: 'ca.digitalfuture.mystories',
      redirectURI: 'https://stories-app-qissasi.firebaseapp.com/__/auth/handler',
      scopes: 'email, name',
      state: '1256',
      nonce: hashedNonce
    }
    if (isPlatform('hybrid')) {
      setAppleLinking(true)
      await SignInWithApple.authorize(options)
        .then((result: SignInWithAppleResponse) => {
          // console.log('result', result)
          if (result.response && result.response.identityToken) {
            const provider = new OAuthProvider('apple.com')
            try {
              const appleCredential = provider.credential({ idToken: result.response.identityToken, rawNonce: 'nonce' })
              // console.log('result.response.identityToken', result.response.identityToken)
              if (AnonymousSignIn) {
                if (!Auth.currentUser) return
                // linkWithCredential(Auth.currentUser, appleCredential)
                //   .then((res: any) => {
                //     console.log('linkWithCredential', res)
                //     setAppleLinking(false)
                //     router.push(navigateToSubscribe())
                //   })
                //   .catch((err: any) => {
                //     console.error('linkWithCredential', err)
                //     if (err.code === 'auth/credential-already-in-use') {
                //       openAppleAlert(result.response.identityToken)
                //     }
                //     setAppleLinking(false)
                //   })
                signInWithCredential(Auth, appleCredential)
                  .then(() => {
                    setLoggedIn(true)
                    editUserData({ isAnonymous: false, email: result.response.email })
                  })
                  .catch((err: any) => {
                    console.error('linkWithCredential', err)
                  })
              } else {
                signInWithCredential(Auth, appleCredential).then(() => {
                  console.log('Successfully signed in with Apple!')
                  setLoggedIn(true)
                })
              }
            } catch (error) {
              console.log('signInWithCredential error', error)
            }
          }
        })
        .catch((error) => {
          console.log('error apple', error)
        })
    } else {
      if (AnonymousSignIn) {
        setAppleLinking(true)
        const provider = new OAuthProvider('apple.com')
        try {
          if (!Auth.currentUser) return
          linkWithPopup(Auth.currentUser, provider)
            .then((result) => {
              // Accounts successfully linked.
              editUserData({ isAnonymous: false, email: result.user.email })
              setAppleLinking(false)
              router.push(navigateToSubscribe())
            })
            .catch((error) => {
              console.error('Link popup error: ', error)
              // handle if account already exists
              if (error.code === 'auth/credential-already-in-use') {
                openAppleAlert()
              }
              setAppleLinking(false)
            })
        } catch (error) {
          setAppleLinking(false)
          console.log('signInWithCredential error', error)
        }
      } else {
        signInWithApple().then(() => {
          console.log('Successfully signed in with Apple!')
          setLoggedIn(true)
        })
      }
    }
  }

  const openGoogleAlert = (idToken: string) => {
    presentAlert({
      header: 'هذا الحساب تم إنشاؤه مسبقاً',
      message: 'هذا الحساب تم إنشاؤه مسبقاً، يمكنك تسجيل الدخول بواسطة هذا البريد الإلكتروني.',
      buttons: [
        {
          text: 'تسجيل الدخول',
          handler: () => {
            isPlatform('hybrid')
              ? signInWithCredential_({ idToken })
                  .then((res) => {
                    setLoggedIn(true)
                  })
                  .catch((err) => {
                    console.error('signInWithCredential_', err)
                  })
              : signInWithGoogle()
          },
          cssClass: 'alert-ok-button'
        },
        {
          text: 'إلغاء',
          role: 'cancel',
          cssClass: 'alert-cancel-button'
        }
      ]
    })
  }

  const openAppleAlert = (idToken?: string) => {
    presentAlert({
      header: 'هذا الحساب تم إنشاؤه مسبقاً',
      message: 'هذا الحساب تم إنشاؤه مسبقاً، يمكنك تسجيل الدخول بواسطة هذا البريد الإلكتروني.',
      buttons: [
        {
          text: 'تسجيل الدخول',
          handler: async () => {
            if (isPlatform('hybrid')) {
              const provider = new OAuthProvider('apple.com')
              const hashedNonce = await sha256('nonce')
              const appleCredential = provider.credential({ idToken: idToken, rawNonce: hashedNonce })
              signInWithCredential(Auth, appleCredential)
                .then(() => {
                  setLoggedIn(true)
                })
                .catch((err) => {
                  console.error('signInWithCredential', err)
                })
            } else {
              signInWithApple()
            }
          },
          cssClass: 'alert-ok-button'
        },
        {
          text: 'إلغاء',
          role: 'cancel',
          cssClass: 'alert-cancel-button'
        }
      ]
    })
  }

  if (googleError?.code) {
    console.error(googleError)
    const message = Errors[googleError.code]
    if (message !== errorGoogleMsg) {
      presentToast({
        message,
        duration: 3000,
        position: 'top',
        color: 'danger',
        cssClass: 'text-center'
      })
      setErrorGoogleMsg(message)
    }
  }

  if (appleError?.code) {
    console.error(appleError)
    const message = Errors[appleError.code]
    if (message !== errorAppleMsg) {
      presentToast({
        message: message ? message : appleError.message,
        duration: 3000,
        position: 'top',
        color: 'danger',
        cssClass: 'text-center'
      })
      setErrorAppleMsg(message)
    }
  }

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-between">
        <IonCol size={IS_ANDROID ? '12' : '6'} className="px-1">
          <IonButton
            disabled={!connected}
            color={'light'}
            shape="round"
            // fill="clear"
            size="large"
            onClick={handleGoogleSignIn}
            // style={{ '--padding-start': '15px', '--padding-end': '15px' }}
          >
            {googleLoading || googleLinking ? (
              <IonSpinner name="dots" className="w-11 h-11" color={'tertiary'} />
            ) : (
              <IonImg src="/assets/icon/ic_google.svg" alt="google logo" className="w-11 h-11" />
            )}
          </IonButton>
        </IonCol>
        <IonCol size="6" className="px-1">
          <IonButton
            disabled={!connected}
            color={'light'}
            shape="round"
            // fill="clear"
            size="large"
            onClick={handleAppleSignIn}
            className={twMerge(IS_ANDROID && 'hidden')}
            // style={{ '--padding-start': '15px', '--padding-end': '15px' }}
          >
            {appleLoading || appleLinking ? (
              <IonSpinner name="dots" className="w-11 h-11" color={'tertiary'} />
            ) : (
              <img src="/assets/icon/ic_apple.svg" alt="apple logo" className="w-11 h-11" />
            )}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default SocialAuth
