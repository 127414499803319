import { FC } from 'react'
import { IonButton, IonIcon, IonCard, IonCardContent, IonThumbnail, IonSkeletonText, useIonToast } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
// components
import { Thumbnail } from '../../components'
// hooks
import { useGetBookOfTheWeek } from '../../hooks/useLibrary'
import useModalState from '../../hooks/useState'
import { useAuth } from '../../contexts/AuthContext'
import { getDownloadUrl } from '../../utils/getDownloadUrl'

/**
 * Functional component representing the Book of the Week section.
 * Fetches the book of the week data and displays it with conditional loading states.
 * Utilizes Ionic components for UI elements and custom hooks for state management.
 */
const BookOfWeek: FC = () => {
  const { user } = useAuth()
  const {
    data: bookOfTheWeek,
    isLoading: bookOfWeekLoading = true,
    error: bookOfWeekError
  } = useGetBookOfTheWeek(user.uid)

  const { dispatch } = useModalState()

  const [presentToast] = useIonToast()

  if (bookOfWeekError) {
    console.log('error', bookOfWeekError)
    presentToast({
      message: bookOfWeekError as string,
      duration: 3000,
      color: 'danger',
      cssClass: 'text-center'
    })
  }

  return (
    <div className="self-start">
      <h3 className="my-4">قصة الأسبوع</h3>
      <IonCard className="flex flex-row items-center self-start justify-between max-w-screen-sm gap-4 px-4 bg-primary-light rounded-xl">
        {bookOfWeekLoading ? (
          <div className="flex flex-row items-center justify-between w-full">
            <IonCardContent className="flex flex-col w-full space-y-6">
              <IonSkeletonText animated className="w-3/5 p-2" />
              <p>
                <IonSkeletonText animated className="w-3/4" />
              </p>
              {Array.from({ length: 1 }).map((_, i) => (
                <IonSkeletonText key={i} animated className="w-full" />
              ))}
              <IonSkeletonText animated className="w-1/3 px-4 py-2" />
            </IonCardContent>
            <IonThumbnail className="w-[100px] h-[150px] sm:w-[135px] sm:h-[210px] sm:my-4">
              <IonSkeletonText animated />
            </IonThumbnail>
          </div>
        ) : (
          <div className="flex flex-row items-center justify-between">
            <div className="my-4 ml-2 space-y-4">
              <h4 className="font-bold line-clamp-1">{bookOfTheWeek?.title}</h4>
              <p className="text-lg line-clamp-3">{bookOfTheWeek?.description}</p>
              <IonButton size="small" onClick={() => dispatch({ type: 'SHOW', payload: bookOfTheWeek?.folderName })}>
                اقــرأ الآن
                <IonIcon icon={chevronBack} />
              </IonButton>
            </div>
            <Thumbnail
              withShadow
              bid={bookOfTheWeek?.folderName as string}
              downloadUrl={getDownloadUrl(
                bookOfTheWeek?.folderName as string,
                bookOfTheWeek?.level as number,
                'cover.jpg'
              )}
              classname="flex-[0.3] w-[100px] h-[150px] sm:w-[135px] sm:h-[210px]"
              recordedOverlay={false}
            />
          </div>
        )}
      </IonCard>
    </div>
  )
}

export default BookOfWeek
