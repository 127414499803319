import { FC } from 'react'
import { IonButton } from '@ionic/react'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useUserAddBook } from '../../hooks/useUserQueries'
import { BookWithStatistics } from '../../types/book'

interface AddButtonProps {
  book: BookWithStatistics
}

const AddButton: FC<AddButtonProps> = ({ book }) => {
  const { user } = useAuth()
  const { mutate: addBook, isLoading, isError, error } = useUserAddBook(user?.uid, book)

  if (isError || error) {
    console.log('error', isError, error)
    // presentToast({
    //   message: Errors[error as string] || error,
    //   duration: 3000,
    //   color: 'danger',
    //   cssClass: 'text-center'
    // })
  }

  return (
    <IonButton
      disabled={isLoading}
      onClick={() => addBook()}
      // expand="block"
      color="quaternary"
      // shape="round"
      // className="2xl:text-xl"
      className="text-sm md:text-xl w-[100px] lg:w-[140px]"
    >
      {isLoading ? 'جاري الإضافة...' : 'أضف إلى مكتبتي'}
    </IonButton>
  )
}

export default AddButton
