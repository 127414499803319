import { getDeviceTypeAndOrientation } from './deviceDetector'

export const getRightRouter = () => {
  const deviceType = getDeviceTypeAndOrientation()

  if (deviceType === 'desktop' || deviceType === 'tablet-landscape') {
    return '/d'
  } else {
    return '/tabs'
  }
}
