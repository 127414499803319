import { FC } from 'react'
import { useLocation } from 'react-router'
import { IonicSlides, IonImg, IonItem, isPlatform } from '@ionic/react'
// swiper
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Grid, Pagination } from 'swiper/modules'
import { twMerge } from 'tailwind-merge'
// import { getDeviceTypeAndOrientation } from '../utils/deviceDetector'

const LEVELS = [
  { level: 1, name: 'مبتدئ' },
  { level: 2, name: 'متوسط' },
  { level: 3, name: 'متقدم' },
  { level: 4, name: 'متقن' },
  { level: 5, name: 'متفوق' }
]

const CATEGORIES = [
  { id: 1, name: 'قصَّة رمزيَّة تعليميَّة' },
  { id: 2, name: 'قصَّة خياليَّة' },
  { id: 3, name: 'قصَّة واقعيَّة' },
  { id: 4, name: 'مَثَلٌ خرافيٌّ' },
  { id: 5, name: 'قصَّة واقعيَّة تعليميَّة' },
  { id: 6, name: 'قصَّة رمزيَّة' },
  { id: 7, name: 'أسطورة' },
  { id: 8, name: 'قصَّة واقعيَّة تاريخيَّة' },
  { id: 9, name: 'قصَّة خياليَّة عالميَّة' },
  { id: 10, name: 'مَثَلٌ خرافيٌّ (إسلاميٌّ)' },
  { id: 11, name: 'قصَّة إسلاميَّة' },
  { id: 12, name: 'كوميديا رومانسيَّة' },
  { id: 13, name: 'تراجيديا رومانسيَّة' },
  { id: 14, name: 'تراجيديا رومانسيَّة وكوميديَّة' },
  { id: 15, name: 'قصَّة علميَّة تاريخيَّة' },
  { id: 16, name: 'خيال علميّ وفانتازيا' },
  { id: 17, name: 'تراجيديا' },
  { id: 18, name: 'قصَّة واقعيَّة إسلاميَّة' },
  { id: 19, name: 'قصَّة الجريمة والغموض' },
  { id: 20, name: 'مغامرات وأساطير' },
  { id: 21, name: 'قصَّة شعبيَّة هزليَّة' },
  { id: 22, name: 'سِيَر وأعلام' },
  { id: 23, name: 'قصَّة الإثارة والغموض' },
  { id: 24, name: 'قصّة الخيال العلميّ' }
]

const ARABI21 = [
  { id: 1, name: 'أ' },
  { id: 2, name: 'ب' },
  { id: 3, name: 'ج' },
  { id: 4, name: 'د' },
  { id: 5, name: 'ه' },
  { id: 6, name: 'و' },
  { id: 7, name: 'ز' },
  { id: 8, name: 'ح' },
  { id: 9, name: 'ط' },
  { id: 10, name: 'ي' },
  { id: 11, name: 'ك' },
  { id: 12, name: 'ل' },
  { id: 13, name: 'م' },
  { id: 14, name: 'ن' },
  { id: 15, name: 'س' },
  { id: 16, name: 'ع' },
  { id: 17, name: 'ف' },
  { id: 18, name: 'ص' },
  { id: 19, name: 'ق' }
]

SwiperCore.use([Navigation, Grid, Pagination, IonicSlides])

// const is_DESKTOP = getDeviceTypeAndOrientation() === 'desktop'
// const is_TABLET_LANDESCAPE = getDeviceTypeAndOrientation() === 'tablet-landscape'

const SearchPageView: FC = () => {
  const location = useLocation()
  // const [showMore, setShowMore] = useState(false)

  // const initialCategories = CATEGORIES.slice(0, 6) // First 2 rows (3 columns each)
  // const remainingCategories = CATEGORIES.slice(6) // Remaining categories

  const options = {
    slidesPerView: 10,
    spaceBetween: 10,
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      1536: {
        slidesPerView: 7,
        spaceBetween: 20
      }
    }
  }

  const swiperOptions2 = {
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
        grid: {
          rows: 4
        }
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
        grid: {
          rows: 4
        }
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        grid: {
          rows: 4
        }
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
        grid: {
          rows: 4
        }
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 20,
        grid: {
          rows: 4
        }
      }
    }
  }

  return (
    <div className={twMerge(isPlatform('desktop') && 'p-8')}>
      <div className="mb-8">
        <h3>المستويات</h3>
        <Swiper dir="rtl" className="w-full my-8" {...options}>
          {LEVELS.map((l) => (
            <SwiperSlide key={`level-${l.level}-${l.name}`}>
              <IonItem
                routerLink={
                  location.pathname.includes('/tabs')
                    ? `/tabs/search/explore?level=${l.level}`
                    : `/d/search/explore?level=${l.level}`
                }
                detail={false}
              >
                <div className="flex flex-col items-center justify-center">
                  <IonImg src={`/assets/illustrations/search/book_level_${l.level}.png`} />
                </div>
              </IonItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mb-8">
        <h3>عربي 21</h3>
        <Swiper dir="rtl" navigation={isPlatform('desktop')} className="w-full my-8" {...options}>
          {ARABI21.map((a) => (
            <SwiperSlide key={`arabi21-${a.id}`}>
              <IonItem
                routerLink={
                  location.pathname.includes('/tabs')
                    ? `/tabs/search/explore?arabi21=${a.name}`
                    : `/d/search/explore?arabi21=${a.name}`
                }
                detail={false}
              >
                <IonImg src={`/assets/illustrations/search/arabi21/${a.id}.png`} />
              </IonItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mb-8">
        <h3>التصنيفات</h3>
        <Swiper
          dir="rtl"
          grid={{
            rows: 4,
            fill: 'row'
          }}
          pagination={{
            clickable: true,
            type: 'bullets',
            bulletClass: 'swiper-pagination-bullet custombullets'
          }}
          modules={[Grid]}
          className="px-8 w-full pb-14 pt-4"
          centerInsufficientSlides
          {...swiperOptions2}
        >
          {CATEGORIES.map((c) => (
            <SwiperSlide key={`category-${c.id}-${c.name}`}>
              <IonItem
                detail={false}
                routerLink={
                  location.pathname.includes('/tabs')
                    ? `/tabs/search/explore?category=${c.name}`
                    : `/d/search/explore?category=${c.name}`
                }
                className="bg-gradient-to-tl from-gray-100 to-white rounded-md w-48 text-center border border-1 border-gray-200 shadow-inner overflow-hidden text-ellipsis whitespace-nowrap"
              >
                <h3 className="text-lg font-bold w-full">{c.name}</h3>
              </IonItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default SearchPageView
