import { FC, useState, useCallback } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  isPlatform,
  IonTitle
} from '@ionic/react'
import { PageBookmarks, Records } from '../../components'
import { twMerge } from 'tailwind-merge'

const SEGMENTS = [
  { value: 'pages', label: 'مرجعياتك', component: <PageBookmarks /> },
  { value: 'records', label: 'تسجيلاتك', component: <Records /> }
]

const UserBookmarks: FC = () => {
  const [segment, setSegment] = useState('pages')

  const handleFilterChange = useCallback(
    (e: CustomEvent) => {
      setSegment(e.detail.value)
    },
    [setSegment]
  )

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color="tertiary" className="rounded-b-3xl" style={{ '--padding-bottom': '0px' }}>
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <div
              className={twMerge(
                'flex flex-row justify-center items-center gap-4 mx-auto',
                isPlatform('ios') && 'my-4'
              )}
            >
              <IonIcon
                aria-hidden="true"
                icon={'/assets/icon/ic_bookmark_f.svg'}
                color="inherit"
                className="inline-block w-6 h-6"
              />
              <IonTitle className="p-0 font-bold" size="large">
                مفكّرتي
              </IonTitle>
            </div>
            <IonSegment
              color="light"
              value={segment}
              className="w-full px-4 text-3xl sm:px-8"
              onIonChange={handleFilterChange}
            >
              {SEGMENTS.map((s, idx) => (
                <IonSegmentButton
                  key={`${s.label}__${idx}`}
                  value={s.value}
                  style={{
                    '--indicator-height': isPlatform('ios') ? '2px' : '3px',
                    '--indicator-transform': isPlatform('ios') ? 'translate3d(0, 27px, 0)' : ''
                  }}
                >
                  <IonLabel>
                    <span className="text-lg sm:text-xl">{s.label}</span>
                  </IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="flex flex-col items-center justify-center gap-8 space-y-10 ion-padding">
        {segment === 'pages' && <PageBookmarks />}
        {segment === 'records' && <Records />}
      </IonContent>
    </IonPage>
  )
}

export default UserBookmarks
