import { FC, useEffect, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonThumbnail,
  isPlatform
} from '@ionic/react'
// import { search } from 'ionicons/icons'
import { Thumbnail } from '../../components'
// hooks
import useModalState from '../../hooks/useState'
import { useSearch } from '../../hooks/useSearch'
// utils
import { getDownloadUrl } from '../../utils/getDownloadUrl'
import { twMerge } from 'tailwind-merge'
// import { useAlert } from '../../contexts/AlertContext'
import { SearchPageView } from '../../views'

const Skeleton: FC = () => (
  <IonList lines="inset" className="mt-4 space-y-8">
    {Array.from({ length: 6 }).map((_, idx) => (
      <IonItem key={`sk1__${idx}`} style={{ '--background': 'transparent' }}>
        <IonThumbnail slot="start" className="w-[140px] h-[225px] sm:w-[200px] sm:h-[300px]">
          <IonSkeletonText animated />
        </IonThumbnail>
        <IonList className="min-w-full">
          <IonItem style={{ '--background': 'transparent' }}>
            <IonLabel>
              <IonSkeletonText animated className="w-3/5 p-2 mx-auto" />
            </IonLabel>
          </IonItem>
          {Array.from({ length: 3 }).map((_, i) => (
            <IonItem key={`sk2__${i}`} className="w-full" style={{ '--background': 'transparent' }}>
              <IonSkeletonText animated className="w-full" />
            </IonItem>
          ))}
        </IonList>
      </IonItem>
    ))}
  </IonList>
)

const NotFound: FC = () => (
  <div className="flex flex-col items-center justify-center h-full">
    <h3 className="mt-4 text-2xl">لا يوجد نتائج مطابقة لبحثك</h3>
  </div>
)

const Search: FC = () => {
  const { dispatch } = useModalState()

  // const { setAlert } = useAlert()

  const [searchText, setSearchText] = useState('')
  const [searchBegin, setSearchBegin] = useState(false)

  const { data, isLoading, isError, error, mutate } = useSearch()

  // useEffect(() => {
  //   // setAlert(!connected)
  //   console.log('no internet connection')
  // }, [setAlert])

  useEffect(() => {
    if (searchText.length > 0)
      mutate({
        text: searchText,
        content: false
      })
  }, [mutate, searchText])

  const handleSearch = (e: any) => {
    setSearchBegin(true)
    if (e.target.value.length === 0) setSearchBegin(false)
    console.log(e.target.value)
    setSearchText(e.target.value)
  }

  if (isError) {
    console.log('isError', isError, error)
  }

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar color="quaternary" className="rounded-b-3xl">
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <div
              className={twMerge(
                'flex flex-row justify-center items-center gap-4 mx-auto',
                isPlatform('ios') && 'my-4'
              )}
            >
              {/* <IonIcon aria-hidden="true" icon={search} color="inherit" className="w-6 h-6" /> */}
              <h3 className="p-0">استكشف</h3>
            </div>
            <IonSearchbar
              animated
              color="light"
              inputMode="search"
              showClearButton="focus"
              placeholder="ابحث عن ما تريد"
              className="max-w-2xl"
              // style={
              //   {
              //     '--border-radius': '12px',
              //     padding: '0px',
              //     marginBottom: isPlatform('ios') ? '5px' : '0px'
              //   }
              // }
              debounce={1000}
              onIonInput={handleSearch}
              onIonCancel={() => {
                console.log('cancel')
                setSearchBegin(false)
                setSearchText('')
              }}
            />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {isLoading && !data && <Skeleton />}
        {searchBegin && isError && <NotFound />}
        {searchBegin && !isLoading && !isError && data?.length === 0 && <NotFound />}
        {searchBegin && data && data?.length > 0 && (
          <IonList lines="inset" className="mt-4">
            {data?.map((book, idx) => (
              <IonItem
                key={`${book.title}__${idx}`}
                style={{ '--background': 'transparent' }}
                onClick={() => {
                  dispatch({ type: 'SHOW', payload: book.folderName })
                }}
                className="w-full pt-4 transition-all duration-300 ease-in-out border-b-2 border-gray-200 hover:bg-gray-100 hover:cursor-pointer"
                // routerLink={`/book/${book.folderName}`}
              >
                <Thumbnail
                  bid={book.folderName}
                  downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                  onClick={() => {
                    // console.log("Network status.connected", connected)
                    dispatch({ type: 'SHOW', payload: book.folderName })
                  }}
                />
                <IonList className="w-full min-h-full bg-transparent">
                  <IonItem className="w-full" style={{ '--background': 'transparent' }}>
                    <h3 className="text-2xl font-bold font-29LTKaff">{book.title}</h3>
                  </IonItem>
                  <IonItem style={{ '--background': 'transparent' }}>
                    <IonLabel className="ion-text-wrap">
                      <span className="text-lg line-clamp-2 sm:text-xl font-29LTKaff">{book.description}</span>
                    </IonLabel>
                  </IonItem>
                  <IonItem style={{ '--background': 'transparent' }}>
                    <IonLabel color={'secondary'} className="ion-text-wrap">
                      <span className="font-bold">العبرة: </span>
                      {book.keywords.map((k, i) => (
                        <span key={`${k}__${i}`} className="text-sm sm:text-md font-29LTKaff">
                          {k}
                          {i !== book.keywords.length - 1 && ', '}{' '}
                        </span>
                      ))}
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonItem>
            ))}
          </IonList>
        )}
        {/* <Explore show={!searchBegin} /> */}
        <div className="p-6">
          <SearchPageView />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Search
