import { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { useSearch } from '../../hooks/useSearch'
import { BooksGrid, NotFound } from '../../components'
import { formatLevel } from '../../utils/format'

const Skeleton = () => {
  const skeletonItems = Array.from({ length: 12 }, (_, i) => i)

  return (
    <div className="grid grid-cols-2 gap-4 p-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {skeletonItems.map((_, index) => (
        <div key={index} className="flex flex-col items-center">
          <IonThumbnail slot="start" className="w-[140px] h-[225px] sm:w-[200px] sm:h-[300px]">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonSkeletonText animated className="w-4/5 h-5 mt-2" />
        </div>
      ))}
    </div>
  )
}

const SearchExplorePage: FC<RouteComponentProps> = ({ location }) => {
  // const { query } = match.params as { query: string }
  const searchParams = new URLSearchParams(location.search)

  const level = searchParams.get('level')
  const arabi21 = searchParams.get('arabi21')
  const category = searchParams.get('category')
  const searchText = searchParams.get('searchText') as string

  const { data: books, isLoading, mutate, isError } = useSearch()

  //check if platform is only Desktop and not mobile or tablet
  const is_DESKTOP = isPlatform('desktop') && !isPlatform('mobileweb') && !isPlatform('mobile') && !isPlatform('tablet')

  useEffect(() => {
    if (level) {
      mutate({ filter: `?level=${level}` })
    }
    if (arabi21) {
      mutate({ filter: `?arabi21=${arabi21}` })
    }
    if (category) {
      mutate({ filter: `?category=${category}` })
    }
    if (searchText) {
      mutate({
        text: searchText,
        content: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, searchText])

  const renderTitle = () => {
    if (searchText) {
      return `نتائج البحث عن: ${searchText}`
    }
    if (level) {
      return `مستوى ${formatLevel(Number(level))}`
    }
    if (category) {
      return category
    }
    if (arabi21) {
      return `مستوى "${arabi21}" من عربي 21`
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar color="light">
          <IonButtons slot="end">
            <IonBackButton icon={arrowBack} defaultHref={is_DESKTOP ? '/d/search' : '/tabs/search'} text="" />
          </IonButtons>
          <IonTitle slot="start">{renderTitle()}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        {isLoading && !books && <Skeleton />}
        {(isError || (!isError && !isLoading && books?.length === 0)) && (
          <NotFound
            title={arabi21 ? 'هذا القسم قيد الإعداد' : 'لا يوجد نتائج مطابقة لبحثك'}
            subtitle={
              arabi21 ? 'تصفّح المكتبة للعثور على قصص تناسب مستوى القراءة الخاص بك' : 'جرّب كلمات أخرى أو تصفّح المكتبة'
            }
            img="/assets/illustrations/library.svg"
          />
        )}
        {books && books.length > 0 && <BooksGrid books={books} />}
      </IonContent>
    </IonPage>
  )
}

export default SearchExplorePage
