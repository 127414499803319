import { isPlatform } from '@ionic/react';

export const getDeviceTypeAndOrientation = () => {
  const isDesktop = !isPlatform('mobile') && !isPlatform('tablet') && !isPlatform('ipad');
  const isTablet = isPlatform('tablet') || isPlatform('ipad');
  const isMobile = isPlatform('mobile');
  const isLandscape = window.innerWidth > window.innerHeight;

  if (isDesktop) {
    return 'desktop';
  } else if (isTablet && isLandscape) {
    return 'tablet-landscape';
  } else if (isTablet && !isLandscape) {
    return 'tablet-portrait';
  } else if (isMobile) {
    return 'mobile';
  }
  return 'unknown';
};