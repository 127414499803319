import { Suspense, lazy, useEffect, useState, useCallback } from 'react'
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { IonRouterOutlet, isPlatform } from '@ionic/react'
import { useAuth } from './contexts/AuthContext'
import { useNetwork } from './contexts/NetworkContext'
import { PrivateRoute, BookDetails, OfflineAlert } from './components'
import Tabs from './pages/Tabs'
import { getDeviceTypeAndOrientation } from './utils/deviceDetector'

const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div className="z-50 flex items-center justify-center h-full bg-white">
          <img src="assets/loader.gif" className="logo" alt={'loader gif'} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

const Desktop = Loadable(lazy(() => import('./pages/Desktop')))
const Login = Loadable(lazy(() => import('./pages/Authentication/Login')))
const Register = Loadable(lazy(() => import('./pages/Authentication/Register')))
const Story = Loadable(lazy(() => import('./pages/Story/Story')))
const Subscription = Loadable(lazy(() => import('./pages/Subscription/Subscription')))
const Promocode = Loadable(lazy(() => import('./pages/Subscription/Promocode')))
const ContactUs = Loadable(lazy(() => import('./pages/Support/ContactUs')))
const Profile = Loadable(lazy(() => import('./pages/Profile')))

const Routes: React.FC = () => {
  const { user } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { connected } = useNetwork()

  const render_by_platform_offline = () => {
    if (isPlatform('desktop')) {
      return '/d/user-library'
    } else {
      return '/tabs/user-library'
    }
  }

  const renderByPlatform = useCallback((orientationType: string, pathname: string) => {
    const isRootPath = pathname === '/'
    const isNotDesktopOrTabsPath = !pathname.includes('/d') && !pathname.includes('/tabs')

    if (orientationType === 'portrait') {
      if (isRootPath) {
        return '/tabs/library'
      } else if (isNotDesktopOrTabsPath) {
        return pathname
      } else {
        return pathname.replace('/d', '/tabs')
      }
    } else {
      if (isRootPath) {
        return '/d/library'
      } else if (isNotDesktopOrTabsPath) {
        return pathname
      } else {
        return pathname.replace('/tabs', '/d')
      }
    }
  }, [])

  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait')
  const [lastVisitedPath, setLastVisitedPath] = useState(location.pathname + location.search)

  useEffect(() => {
    setLastVisitedPath(location.pathname + location.search)
  }, [location.pathname, location.search])

  useEffect(() => {
    if (user) {
      setLastVisitedPath(location.pathname + location.search)
    }
  }, [location.pathname, location.search, user])

  useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientationType = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
      const deviceType = getDeviceTypeAndOrientation()
      if (location.pathname.includes('story')) {
        return
      }
      if (deviceType === 'tablet-landscape' || deviceType === 'tablet-portrait') {
        try {
          // ScreenOrientation.lock({ type: ScreenOrientationLockType.Landscape })
          const newPath = renderByPlatform(newOrientationType, location.pathname + location.search)
          setLastVisitedPath(newPath)
          if (newPath) {
            history.replace(newPath)
          } else {
            alert('An error occurred. Please try again.')
            console.error('renderByPlatform returned an invalid path')
          }
        } catch (error) {
          console.error('Error locking screen orientation:', error)
        }
      }
      setOrientation(newOrientationType)
    }

    window.addEventListener('resize', handleOrientationChange)
    return () => window.removeEventListener('resize', handleOrientationChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <IonRouterOutlet dir="rtl">
        <Route path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/anonymous" component={Register} />

        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/subscription" component={Subscription} />
        <PrivateRoute exact path="/subscription/promo" component={Promocode} />
        <PrivateRoute exact path="/subscription" component={Subscription} />
        <PrivateRoute path="/story/:storyId/:pageNum/:readCount/:editMode" component={Story} />
        <Route exact path="/support/contact-us" component={ContactUs} />

        {/* MOBILE ROUTES */}
        <PrivateRoute path="/tabs" component={Tabs} />

        {/* DESKTOP ROUTES */}
        <PrivateRoute path="/d" component={Desktop} />

        {/* <Route
          exact
          path="/"
          render={() => (
            <Redirect to={connected ? renderByPlatform(orientation, lastVisitedPath) : render_by_platform_offline()} />
          )}
        /> */}
        <Redirect
          exact
          from="/"
          to={connected ? renderByPlatform(orientation, lastVisitedPath) : render_by_platform_offline()}
        />
        <Route
          render={() => (
            <Redirect to={connected ? renderByPlatform(orientation, lastVisitedPath) : render_by_platform_offline()} />
          )}
        />
      </IonRouterOutlet>
      {user && <BookDetails />}
      <OfflineAlert />
    </>
  )
}

export default Routes
